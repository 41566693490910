import profile from './assets/images/profile.jpg'
const logotext = "Unicornprofessional Venture";

const meta = {
    title: "Unicornprofessional Venture ",
    description: "I'm Harisha Lakshan Warnakulasuriya .I have Managed this http://www.harishalakshanwarnakulasuriya.com",
};

const introdata = {
    title: "Unicornprofessional Venture",
    animated: {
        first: "We are developing Websites in NODEJS service-based Application and Mobile application in Android and IOS Based Application",
        second: "We are practicing cloud-based Web application development and Deploying Cloud-based full solutions",
        third: "We are managing and highly involved development of several Software-as-a-Service web application solutions",
        forth: "We are highly involved development of realtime Machine Learning Web,Desktop,Mobile Application for Day-to-day use,Specific purposes.",
    },
    description: "Unicornprofessional venture is providing World-class standard Desktop Applications in DotNet and Mobile Application for Android and IOS and World-Class web Applications in PHP,NodeJS,ReactJS,Laravel PHP,Python--Updated according to 2024 April 06",
    your_img_url: profile,
};

const dataabout = {
    title: "About our company",
    aboutme: "Since 2015,We started as Startup company with one member of highly motivated individual.Now we have highly diversifyng the sectors of Web Application Development,Software Development,Mobile Application Developement and Retail Management,Social Media Developement.-Updated according to 2024 March 23",
};
const worktimeline = [{
        jobtitle: "CEO and CFO in unicornprofessional Venture",
        where: "10115,Malabe,SriLanka,",
        date: "2015",
    },
   
];

const skills = [{
        name: "PHP",
        value: 90,
    },
    {
        name: "NODEJS",
        value: 95,
    },
    {
        name: "Javascript",
        value: 95,
    },
    {
        name: "REACTJS",
        value: 95,
    },
    {
        name: "IOS",
        value: 60,
    },
    {
        name: "JAVA",
        value: 95,
    },
    {
        name: "C#",
        value: 90,
    },
    {
        name: "Jquery",
        value: 95,
    },
    {
        name: "Python",
        value: 95,
    },
];

const services = [{
        title: "React UI & UX Design for Web Application Developement",
        description: "Well-developed skills for PHP, JavaScript, HTML, CSS, HTML5, Node.js, jQuery, Experience with Tag Managers: Google Tag Manager, Experience with Commerce Platforms: Magento, Shopify, and Laravel PHP Framework.Good knowledge of working as Blockchain Developer and NFT based BlockChain development cryptographic application and Developing Shopping carts for payment, Orders and Shopping cart FrontEnd and Shopping Cart Backend.",
    },
    {
        title: "Mobile Application Developement",
        description: "Good working knowledge in Native JAVA for Android Studio in Mobile Apps developments",
    },
    {
        title: "Cloud-based Web Application Developemnt",
        description: "Applying all cloud based best practices in DevOps methodologies to fulfill all requirement to develop 24/7 365 days available Application with support of Docker and kubernetes.",
    },
    {
        title: "Windows based Appliacation Development",
        description: "well-developed skills for Pascal,C,C++,Java,Assembly,C#,.Net Windows programming languages for Desktop Apps, Web Apps and Socket programming, Bluetooth Technology, WiFi Technology, Plug-In Architecture, Operating system development,",
    },
    {
        title: "Machine Learning Web application in Cloud -based, Mobile Application and Windows Application Developement",
        description: "I have well-developed knowledge of using Machine Learning and Artifical Intelligence application designing using Linear Regression,Logistic Regression,K-Nearest Neighbors,Support Vector Machines,Naive Bayes,Random Forest,K-means Clustering.",
    },
];

const dataportfolio = [{
        img: "",
        desctiption: "Project 01",
        link: "#",
    },
    
];

const contactConfig = {
    YOUR_EMAIL: "unicornprofessionalbay@gmail.com",
    YOUR_FONE: "+94-75-0788368",
    description: "This website is purely coded by Software/Database/Network/Business Intelligence(BI)/Business Analyst/Network and Security/System/Mobile development Engineer Harisha Lakshan Warnakulasuriya. Contact him by using +94750788368 for further development project proposals.",
    YOUR_SERVICE_ID: "service_0tbhtye",
    YOUR_TEMPLATE_ID: "template_3t76iri",
    YOUR_USER_ID: "QtQtuhxCKoJzWILnd",
};

const socialprofils = {
    github: "https://github.com/harishalakshan/",
    facebook: "https://www.facebook.com/HarishaLakshanWarnakulasuriya",
    linkedin: "https://www.linkedin.com/in/harisha-warnakulasuriya-/",
    twitter: "https://twitter.com/HarishaLakshan",
    youtube: "https://harishalakshanwarnakulasuriya.medium.com/",
    Upwork: "https://www.upwork.com/freelancers/~0150e09dd3ec23f8a9",
};
export {
    meta,
    dataabout,
    dataportfolio,
    worktimeline,
    skills,
    services,
    introdata,
    contactConfig,
    socialprofils,
    logotext,
};